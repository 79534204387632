.container {
    position: relative;
    width: 100%;
    padding: 0;
}

.image {
    display: block;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #6F2369;
}

.container:hover .overlay {
    opacity: 0.9;
}

.text {
    color: white;
    font-size: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 79%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: left;
}
.text {
    color: white;
    font-size: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 79%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: left;
}