/* .form__label {
    font-family: 'Poppins';
    font-size: 1.2rem;
    margin-left: 2rem;
    margin-top: 0.7rem;
    display: block;
    transition: all 0.3s;
    transform: translateY(0rem);
} */

.form__input {
    font-family: 'Poppins', sans-serif;
    color: #333;
    font-size: 1rem;
    font-weight: 300;
    margin: 0 auto;
    padding: 1.1rem 1rem;
    border-radius: 0.2rem;
    background-color: rgba(255, 255, 255, 0.39);
    width: 100%;
    display: block;
    border: 0.1rem solid transparent;
    transition: all 0.3s;
    border-color: rgba(101, 45, 144, 0.4);
    outline: none;
}


.form__input:hover {
    background-color: rgba(101, 45, 144, 0.2);

}

/* .form__input:placeholder-shown+.form__label {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-4rem);
    transform: translateY(-4rem);
} */

textarea {
    resize: none;
    height: 150px;
}